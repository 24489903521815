import React, {FC, useEffect, useRef} from 'react';
import {ModalProps} from '../../types';
import {Translate} from '../../components/translations';
import Button from '../button';
import './styles.scss';

export const Modal: FC<ModalProps> = props => {
  // @ts-ignore

  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [props]);

  const handleClick = (event: any) => {
    return props.modalToggle();
  };

  return (
    <div
      className={`modal ${props.isOpen ? 'show' : ''} ${props.dark ? 'dark' : ''} ${
        props.className ? props.className : ''
      }`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {props.title && (
            <div className="modal-header">
              <h5 className={`modal-title ${props.headerCentered ? 'm-auto' : ''}`}>
                {typeof props.title === 'string' ? <Translate name={props.title} /> : props.title}
              </h5>
            </div>
          )}
          <div className={props.modalFooter === null ? 'modal-body pb_30' : 'modal-body'}>{props.children}</div>
          {props.modalFooter === null ? null : (
            <div className="modal-footer" style={{justifyContent: props.headerCentered ? 'center' : ''}}>
              {props.modalFooter ? (
                props.modalFooter
              ) : (
                <button onClick={props.modalToggle} type="button" className="btn btn-secondary btn-sm">
                  Close
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
