import {gql} from 'apollo-boost';

const mutationFields = `
error
message
`;

export const CONTACT_FORM_SUBMIT = gql`
  mutation ContactFormSubmit($name: String!, $email: String!, $subject: String!, $message: String!, $emailTo: String, $productType: String) {
    contactFormSubmit (name: $name, email: $email, subject: $subject, message: $message, emailTo: $emailTo, productType: $productType) {
     ${mutationFields}
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!, $productType: String!) {
    UserOps {
      login(email: $email, password: $password, productType: $productType) {
        ${mutationFields}
        token
        hash
        twofa_status
        device_key
        refresh_token
      }
    }
  }
`;
export const USER_LOGIN_TWO_FACTOR = gql`
  mutation twofaVerify($email: String!, $code: String!, $productType: String!, $rememberDevice: Boolean) {
    UserOps {
      twofaVerify(email: $email, code: $code, productType: $productType, rememberDevice: $rememberDevice) {
      ${mutationFields}
      token
      hash
      device_key
      refresh_token
      }
    }
  }
`;
export const USER_REGISTRATION = gql`
  mutation UserRegistration($firstName: String!, $lastName: String!, $email: String!, $password: String!, $sessionToken: String!, $promoCode: String, $referralCode: String) {
    UserOps {
      registration(firstName: $firstName, lastName: $lastName, email: $email, password: $password, sessionToken: $sessionToken, promoCode: $promoCode, referralCode: $referralCode, type: "hexomatic") {
      ${mutationFields}
      token
      hash
      device_key
      refresh_token
      }
    }
  }
`;

export const USER_VERIFY = gql`
  mutation User_verify($verifyCode: String!) {
    UserOps {
      verify(verifyCode: $verifyCode) {
         ${mutationFields}
          token
          hash
        }
      }
    }
`;

export const USER_RESET_PASSWORD = gql`
  mutation UserResetPassword($password: String!, $verifyCode: String!, $device_key: String) {
    UserOps {
      resetPassword(password: $password, verifyCode: $verifyCode, device_key: $device_key) {
      ${mutationFields}
      }
    }
  }
`;

export const USER_FORGOT_PASSWORD = gql`
  mutation UserForgotPassword($email: String!, $type: String) {
    UserOps {
      forgotPassword(email: $email, type: $type) {
        ${mutationFields}
      }
    }
  }
`;

export const MAGNET_SUBSCRIBE = gql`
  mutation ($email: String!, $type: String, $comment: String) {
    WatchSubscribeOps {
      launchSubscribe(email: $email, type: $type, comment: $comment) {
        ${mutationFields}
      }
    }
  }
`;

export const MAGNET_VERIFY = gql`
  mutation ($verifyCode: String!) {
    WatchSubscribeOps {
      verify(verifyCode: $verifyCode) {
         ${mutationFields}
         comment
        }
      }
    }
`;

export const VERIFY_NOTIFICATION_EMAIL = gql`
  mutation ($verify_token: String!) {
    UserWatchSettingsOps {
      verifyNotificationEmail(verify_token: $verify_token) {
      ${mutationFields}
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation($cvc: String!, $number: String!, $expiry: String!, $name: String!) {
    UserOps {
      addCreditCard(number: $number, name: $name, expiry: $expiry, cvc: $cvc) {
        error
        message
      }
    }
  }
`;

export const UPGRADE_PLAN = gql`
  mutation($settings: UpdateHexomaticCreditsPackageInput) {
    HexomaticUserOps {
      updateHexomaticCreditsPackage(settings: $settings) {
        required_redirect_url
        error_code
      }
    }
  }
`;

export const HEXOWATCH_SUBSCRIBE = gql`
  mutation($email: String!, $type: String, $comment: String) {
    WatchSubscribeOps {
      launchSubscribe(email: $email, type: $type, comment: $comment) {
        error
        message
      }
    }
  }
`;

export const SUBSCRIBE_VERIFY = gql`
  mutation ($verifyCode: String!) {
    WatchSubscribeOps {
      verify(verifyCode: $verifyCode) {
         ${mutationFields}
        }
      }
    }
`;
export const RESEND_VERIFICATION_EMAIL = gql`
  mutation RESEND_VERIFICATION_EMAIL {
    UserOps {
      resendVerification {
        error
        message
      }
    }
  }
`;

export const ADD_REFERRAL = gql`
  mutation addReferral($referralCode: String) {
    ReferralOps {
      addReferral(referralCode: $referralCode) {
        error
        message
      }
    }
  }
`;
//-------------Stripe/Payment/Mutation--------------------

export const SUBMIT_ENTERPRISE = gql`
  mutation SUBMIT_ENTERPRISE($settings: CreateEnterpriseSectionInput) {
    EnterpriseSectionOps {
      sendEmail(settings: $settings) {
        error
        message
      }
    }
  }
`;

export const ADD_CARD = gql`
  mutation addCard($settings: AddCardInput) {
    BillingOps {
      addCard(settings: $settings) {
        error_code
        cardId
      }
    }
  }
`;

//COMBO PLANS
export const BUY_TIER_STRIPE = gql`
  mutation updateUserComboPackage($settings: UpdateUserComboPackageInput) {
    UserOps {
      updateUserComboPackage(settings: $settings) {
        error_code
        clientSecret
        card
        approveUrl
      }
    }
  }
`;

export const UPDATE_STRIPE_CUSTEMER = gql`
  mutation updateStripeCustemer($settings: UpdateStripeCustomerInput) {
    BillingOps {
      updateStripeCustomer(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($settings: CreatePaymentIntentInput) {
    BillingOps {
      createPaymentIntent(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CREATE_SUBSCRIBTION = gql`
  mutation createSubscription($settings: CreateSubscriptionInput) {
    BillingOps {
      createSubscription(settings: $settings) {
        error_code
      }
    }
  }
`;
export const CANCEL_SUBSCRIBTION = gql`
  mutation cancelSubscription($settings: CancelSubscriptionResult) {
    BillingOps {
      cancelSubscription(settings: $settings) {
        error_code
      }
    }
  }
`;
export const SUBSCRIBTION = gql`
  mutation updateUserHexomaticPackage($settings: UpdateUserHexomaticPackageInput) {
    HexomaticUserOps {
      updateUserHexomaticPackage(settings: $settings) {
        error_code
        card
        clientSecret
        approveUrl
      }
    }
  }
`;
export const CREDIT_SUBSCRIBTION = gql`
  mutation($settings: UpdateHexomaticCreditsPricingPackageInput) {
    BillingOps {
      updateMainUserPremiumCreditsPackage(settings: $settings) {
        error_code
      }
    }
  }
`;

export const LOG_OUT = gql`
  mutation($id: ID, $device_key: String) {
    UserOps {
      logout(id: $id, device_key: $device_key) {
        error
        message
      }
    }
  }
`;
// export const PAYPAL_UPGRADE = gql`
//   mutation executePaypalPostAction($settings: ExecutePaypalPostActionInput) {
//     BillingOps {
//       executePaypalPostAction(settings: $settings) {
//         error_code
//       }
//     }
//   }
// `;

// export const PAYPAL_PAYMENT_APPROVED = gql`
//   mutation paypalPaymentApproved($settings: PaypalPaymentApprovedInput) {
//     BillingOps {
//       paypalPaymentApproved(settings: $settings) {
//         error_code
//       }
//     }
//   }
// `;

export const DEMO_REQUEST_SUBMIT = gql`
  mutation DemoRequestSubmit($settings: DemoRequestInput) {
    DemoRequestOps {
      sendEmail(settings: $settings) {
        error
        message
      }
    }
  }
`;
