import dayjs, {Dayjs} from 'dayjs';

export const URL_RE = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g;
export const DOMAIN_RE = /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const IP_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i;
export const VALID_PASSWORD_RE = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const VALID_EMAIL_RE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_PHONE_RE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

export const isValidUrl = (url: string) =>
  /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9:%._\/+~#=?]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\/+.~#?&//=?]*)/g.test(url);
export const isValidEmail = (mail: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
export const isValidPhoneNumber = (val: string): boolean => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(val);

export const isValidDomain = (domain: string) =>
  /^(?:[a-z0-9:%._\/+~#=?](?:[a-z0-9-:%._\/+~#=?]{0,61}[a-z0-9:%._\/+~#=?])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain,
  );
export const isValidIP = (url: string): boolean => IP_REGEX.test(url);
export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
};
export const purchaseErrors = {
  NO_USER: 'You should be authenticated to perform this action!',
  NO_PAID_PACKAGE:
    'Thanks for your interest. Please note this offer is for existing customers with an active Hexometer or Hexowatch paid plan.',
  INVALID_PRICING_PACKAGE: 'Invalid pricing plan',
  INVALID_PRICING_PACKAGE_INTERVAL: 'Invalid pricing interval',
  CANT_STACK: 'The plan is not stackable',
  CANT_SWITCH_PLAN: 'Not allowed',
  WRONG_PLAN_SELECTED: 'Wrong plan selected',
  ADD_PAYMENT_INFORMATION: 'Please add payment details to continue.',
};

export const tools = [12];
export const hints = () => {
  const arr = [
    'Scrape any website in minutes',
    'Tap into 100+ automations to zip through your work day',
    'Turn the web into your own private data source',
    'Automate time consuming sales & marketing tasks in a few clicks',
    'Save over 32 hours a month by automating tasks',
    'Scale your biz with ready made automations',
    'Create your army of bots to scrape the web & automate tasks',
    'Turn websites into spreadsheets in minutes',
    'The growth hacking platform for sales & marketing teams',
    'Scrape and analyze listings from any website',
    "Find 100's of prospects in a few clicks using Google Maps",
    'Monitor Amazon sellers for specific products',
    'Supercharge your SEO backlinks outreach',
    'Translate ad creatives or products at scale',
    'Create screenshots in bulk for any device size',
    'Perform SEO analysis at scale',
    'Convert images at scale',
  ];
  const random = Math.floor(Math.random() * arr.length);
  return [
    arr[random],
    arr[random + 1] ?? arr[random - 1],
    arr[random + 2] ?? arr[random - 2],
    arr[random + 3] ?? arr[random - 3],
  ];
};

export const voiceContryOptions = [
  {label: 'Afrikaans (South Africa)', value: 'af-ZA'},
  {label: 'Arabic', value: 'ar-XA'},
  {label: 'Bengali (India)', value: 'bn-IN'},
  {label: 'Bulgarian (Bulgaria)', value: 'bg-BG'},
  {label: 'Catalan (Spain)', value: 'ca-ES'},
  {label: 'Chinese (Hong Kong)', value: 'yue-HK'},
  {label: 'Czech (Czech Republic)', value: 'cs-CZ'},
  {label: 'Danish (Denmark)', value: 'da-DK'},
  {label: 'Dutch (Belgium)', value: 'nl-BE'},
  {label: 'Dutch (Netherlands)', value: 'nl-NL'},
  {label: 'English (Australia)', value: 'en-AU'},
  {label: 'English (India)', value: 'en-IN'},
  {label: 'English (UK)', value: 'en-GB'},
  {label: 'English (US)', value: 'en-US'},
  {label: 'Filipino (Philippines)', value: 'fil-PH'},
  {label: 'Finnish (Finland)', value: 'fi-FI'},
  {label: 'French (Canada)', value: 'fr-CA'},
  {label: 'French (France)', value: 'fr-FR'},
  {label: 'German (Germany)', value: 'de-DE'},
  {label: 'Greek (Greece)', value: 'el-GR'},
  {label: 'Gujarati (India)', value: 'gu-IN'},
  {label: 'Hindi (India)', value: 'hi-IN'},
  {label: 'Hungarian (Hungary)', value: 'hu-HU'},
  {label: 'Icelandic (Iceland)', value: 'is-IS'},
  {label: 'Indonesian (Indonesia)', value: 'id-ID'},
  {label: 'Italian (Italy)', value: 'it-IT'},
  {label: 'Japanese (Japan)', value: 'ja-JP'},
  {label: 'Kannada (India)', value: 'kn-IN'},
  {label: 'Korean (South Korea)', value: 'ko-KR'},
  {label: 'Latvian (Latvia)', value: 'lv-LV'},
  {label: 'Malay (Malaysia)', value: 'ms-MY'},
  {label: 'Malayalam (India)', value: 'ml-IN'},
  {label: 'Mandarin Chinese', value: 'cmn-CN'},
  {label: 'Norwegian (Norway)', value: 'nb-NO'},
  {label: 'Polish (Poland)', value: 'pl-PL'},
  {label: 'Portuguese (Brazil)', value: 'pt-BR'},
  {label: 'Portuguese (Portugal)', value: 'pt-PT'},
  {label: 'Romanian (Romania)', value: 'ro-RO'},
  {label: 'Russian (Russia)', value: 'ru-RU'},
  {label: 'Serbian (Cyrillic)', value: 'sr-RS'},
  {label: 'Slovak (Slovakia)', value: 'sk-SK'},
  {label: 'Spanish (Spain)', value: 'es-ES'},
  {label: 'Spanish (US)', value: 'es-US'},
  {label: 'Swedish (Sweden)', value: 'sv-SE'},
  {label: 'Tamil (India)', value: 'ta-IN'},
  {label: 'Telugu (India)', value: 'te-IN'},
  {label: 'Thai (Thailand)', value: 'th-TH'},
  {label: 'Turkish (Turkey)', value: 'tr-TR'},
  {label: 'Ukrainian (Ukraine)', value: 'uk-UA'},
  {label: 'Vietnamese (Vietnam)', value: 'vi-VN'},
];

export const spellcheckToolLanguages: {label: string; value: string}[] = [
  {
    label: 'Belarusian',
    value: 'be-BY',
  },
  {
    label: 'Catalan',
    value: 'ca-ES',
  },
  {
    label: 'Catalan (Valencian)',
    value: 'ca-ES-valencia',
  },
  {
    label: 'Danish',
    value: 'da-DK',
  },
  {
    label: 'Dutch',
    value: 'nl',
  },
  {
    label: 'English (Australian)',
    value: 'en-AU',
  },
  {
    label: 'English (Canadian)',
    value: 'en-CA',
  },
  {
    label: 'English (GB)',
    value: 'en-GB',
  },
  {
    label: 'English (New Zealand)',
    value: 'en-NZ',
  },
  {
    label: 'English (South African)',
    value: 'en-ZA',
  },
  {
    label: 'English (US)',
    value: 'en-US',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'Galician',
    value: 'gl-ES',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'German (Austria)',
    value: 'de-AT',
  },
  {
    label: 'German (Germany)',
    value: 'de-DE',
  },
  {
    label: 'German (Swiss)',
    value: 'de-CH',
  },
  {
    label: 'Greek',
    value: 'el-GR',
  },
  {
    label: 'Irish',
    value: 'ga-IE',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Polish',
    value: 'pl-PL',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Portuguese (Brazil)',
    value: 'pt-BR',
  },
  {
    label: 'Portuguese (Portugal)',
    value: 'pt-PT',
  },
  {
    label: 'Romanian',
    value: 'ro-RO',
  },
  {
    label: 'Russian',
    value: 'ru-RU',
  },
  {
    label: 'Simple German',
    value: 'de-DE-x-simple-language',
  },
  {
    label: 'Slovak',
    value: 'sk-SK',
  },
  {
    label: 'Slovenian',
    value: 'sl-SI',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Swedish',
    value: 'sv',
  },
  {
    label: 'Ukrainian',
    value: 'uk-UA',
  },
];
export const spellcheckDatapoints = [
  '_grammar_spelling_audit_screenshot_image',
  '_grammar_spelling_audit_issues_found',
  '_grammar_spelling_audit_word',
  '_grammar_spelling_audit_iterations',
  '_grammar_spelling_audit_error_type',
  '_grammar_spelling_audit_suggested_replacement',
];
export const genderOptions = [
  // {label: 'Neutral', value: 'NEUTRAL'},
  {label: 'Male', value: 'MALE'},
  {label: 'Female', value: 'FEMALE'},
];

export const textToSpeechCountries = [
  {country: 'Afrikaans', lang: '(South Africa)'},
  {country: 'Arabic'},
  {country: 'Bengali', lang: '(India)'},
  {country: 'Bulgarian', lang: '(Bulgaria)'},
  {country: 'Catalan', lang: '(Spain)'},
  {country: 'Chinese', lang: '(Hong Kong)'},
  {country: 'Czech', lang: '(Czech Republic)'},
  {country: 'Danish', lang: '(Denmark)'},
  {country: 'Dutch', lang: '(Netherlands)'},
  {country: 'English', lang: '(Australia)'},
  {country: 'English', lang: '(United Kingdom)'},
  {country: 'English', lang: '(United States)'},
  {country: 'Filipino', lang: '(Philippines)'},
  {country: 'Finnish', lang: '(Finland)'},
  {country: 'French', lang: '(Canada)'},
  {country: 'French', lang: '(France)'},
  {country: 'German', lang: '(Germany)'},
  {country: 'Gujarati', lang: '(India)'},
  {country: 'Hindi', lang: '(India)'},
  {country: 'Hungarian', lang: '(Hungary)'},
  {country: 'Icelandic', lang: '(Iceland)'},
  {country: 'Indonesian', lang: '(Indonesia)'},
  {country: 'Italian', lang: '(Italy)'},
  {country: 'Japanese', lang: '(Japan)'},
  {country: 'Kannada', lang: '(India)'},
  {country: 'Korean', lang: '(South Korea)'},
  {country: 'Latvian', lang: '(Latvia)'},
  {country: 'Malayalam', lang: '(India)'},
  {country: 'Mandarin', lang: '(China)'},
  {country: 'Norwegian', lang: '(Norway)'},
  {country: 'Polish', lang: '(India)'},
  {country: 'Portuguese', lang: '(Brazil)'},
  {country: 'Portuguese', lang: '(Portugal)'},
  {country: 'Romanian', lang: '(Romania)'},
  {country: 'Russian', lang: '(Russia)'},
  {country: 'Serbian', lang: '(Cyrillic)'},
  {country: 'Slovak', lang: '(Slovakia)'},
  {country: 'Spanish', lang: '(Spain)'},
  {country: 'Spanish', lang: '(United States)'},
  {country: 'Swedish', lang: '(Sweden)'},
  {country: 'Tamil', lang: '(India)'},
  {country: 'Telugu', lang: '(India)'},
  {country: 'Thai', lang: '(Thailand)'},
  {country: 'Turkish', lang: '(Turkey)'},
  {country: 'Ukrainian', lang: '(Ukraine)'},
  {country: 'Vietnamese', lang: '(Vietnam)'},
];
export const spellcheckLanguages = [
  {country: 'Belarusian'},
  {country: 'Catalan'},
  {country: 'Catalan', lang: '(Valencian)'},
  {country: 'Danish'},
  {country: 'Dutch'},
  {country: 'English', lang: '(Australian)'},
  {country: 'English', lang: '(Canadian)'},
  {country: 'English', lang: '(GB)'},
  {country: 'English', lang: '(New Zealand)'},
  {country: 'English', lang: '(South African)'},
  {country: 'English', lang: '(US)'},
  {country: 'French'},
  {country: 'Galician'},
  {country: 'German'},
  {country: 'German', lang: '(Austria)'},
  {country: 'German', lang: '(Germany)'},
  {country: 'German', lang: '(Swiss)'},
  {country: 'Greek'},
  {country: 'Irish'},
  {country: 'Italian'},
  {country: 'Polish'},
  {country: 'Portuguese'},
  {country: 'Portuguese', lang: '(Brazil)'},
  {country: 'Portuguese', lang: '(Portugal)'},
  {country: 'Romanian'},
  {country: 'Russian'},
  {country: 'Simple German'},
  {country: 'Slovak'},
  {country: 'Slovenian'},
  {country: 'Spanish'},
  {country: 'Swedish'},
  {country: 'Ukrainian'},
];
const getDateDiff = (
  oldDate: Dayjs,
  newDate: Dayjs,
): {
  years: number;
  months: number;
  days: number;
} => {
  const diff = {
    years: newDate.diff(oldDate, 'year'),
    months: 0,
    days: 0,
  };
  oldDate = oldDate.add(diff.years, 'year');
  diff.months = newDate.diff(oldDate, 'month');
  oldDate.add(diff.months, 'month');

  oldDate = oldDate.add(diff.months, 'month');
  diff.days = newDate.diff(oldDate, 'day');

  return diff;
};
const getDiffInDays = (
  oldDate: Dayjs,
  newDate: Dayjs,
): {
  days: number;
} => {
  const diff = {
    days: 0,
  };
  diff.days = newDate.diff(oldDate, 'day');

  return diff;
};

const generateDate = (
  newDate: string,
  oldDate: string,
): {
  years: string;
  months: string;
  days: string;
  diffInDays: number;
} => {
  const diff = getDateDiff(dayjs(oldDate), dayjs(newDate));
  const diffInDays = getDiffInDays(dayjs(oldDate), dayjs(newDate)).days;
  const years: string = diff.years && diff.years >= 1 ? `${diff.years} year${diff.years === 1 ? '' : 's '} ` : '';
  const months: string = diff.months && diff.months >= 1 ? `${diff.months} month${diff.months === 1 ? '' : 's '} ` : '';
  const days: string =
    diff.days === 0
      ? 'Today'
      : diff.days >= 1
      ? `${diff.days.toFixed()} day${diff.days === 1 ? '' : 's '} `
      : diff.days < 0
      ? `${(diff.days * -1).toFixed()} day${diff.days === -1 ? '' : 's '} ago`
      : '';
  return {
    years,
    months,
    days,
    diffInDays,
  };
};
export const isoDateToHtmlDate = (
  isoDate: string,
  type: string,
  withDots?: boolean,
  // subType?: string,
): string | number => {
  let date = new Date(isoDate);
  let dtString = '';
  let monthString = '';
  const newDate: string = new Date().toISOString();
  if (type === 'registeredAt') {
    if (date.getDate() < 10) {
      dtString = '0' + date.getDate();
    } else {
      dtString = String(date.getDate());
    }
    if (date.getMonth() + 1 < 10) {
      monthString = '0' + Number(date.getMonth() + 1);
    } else {
      monthString = String(date.getMonth() + 1);
    }
    return withDots
      ? `${monthString}.${dtString}.${date.getFullYear()}`
      : `${monthString} / ${dtString} / ${date.getFullYear()}`;
  } else if (type === 'expiresAt') {
    const diff = generateDate(isoDate, newDate);
    return `${diff.years}${diff.months}${diff.days}`;
  } else if (type === 'lastModified') {
    const diff = generateDate(newDate, isoDate);
    return `${diff.years}${diff.months}${diff.days} ago`;
  } else {
    const diff = generateDate(newDate, isoDate);
    return `${diff.years}${diff.months}${diff.days}`;
  }
};

export const returnAPILink = () => {
  // return 'https://api.hexomatic.com/tools';

  //@ts-ignore
  const dev =
    (global && global.window && global.window.location && global.window.location.host.includes('37.186.119.181')) ||
    global.window.location.host.includes('localhost:3278');
  if (dev) {
    return 'http://37.186.119.181:3013/tools';
  } else {
    return 'https://api.hexomatic.com/tools';
  }
};

export const returnSocketLink = () => {
  // return 'wss://api.hexomatic.com/tools/ws';

  //@ts-ignore
  const dev =
    (global && global.window && global.window.location && global.window.location.host.includes('37.186.119.181')) ||
    global.window.location.host.includes('localhost:3278');
  if (dev) {
    return 'ws://37.186.119.181:3013/tools/ws';
  } else {
    return 'wss://api.hexomatic.com/tools/ws';
  }
};

export const freeToolsErrors = [
  'Credits limit reached.',
  'Credits and storage limit reached.',
  'Storage limit reached.',
  'Premium credits limit reached.',
];
export const accessibilityStandards = [
  {label: 'IBM Accessibility', value: 'IBM_Accessibility'},
  {label: 'WCAG 2.1', value: 'WCAG_2_1'},
  {label: 'WCAG 2.0', value: 'WCAG_2_0'},
];

export const toolsList: ReadonlyArray<{label: string; value: string}> = [
  {label: 'All', value: ''},
  {label: 'Text to Speech', value: 'TEXT_TO_SPEECH'},
  {label: 'Discover Tech Stack', value: 'TECH_STACK'},
  {label: 'Discover WHOIS', value: 'WHO_IS'},
  {label: 'Accessibility Audit', value: 'ACCESSIBILITY_CHECKER'},
  {label: 'Grammar & Spelling Audit', value: 'GRAMMAR_SPELLING'},
  {label: 'Content analysis', value: 'GET_WORD_COUNT'},
  {label: 'Screenshot capture', value: 'DEVICE_SCREENSHOT'},
  {label: 'QR code generator', value: 'QR_CODE'},
];

export const toolsPath: {[key: string]: string} = {
  'text-to-speech': 'TEXT_TO_SPEECH',
  'tech-stack': 'TECH_STACK',
  'domain-whois': 'WHO_IS',
  'accessibility-audit': 'ACCESSIBILITY_CHECKER',
  'grammar-spelling-audit': 'GRAMMAR_SPELLING',
  'word-count': 'GET_WORD_COUNT',
  'screenshot-capture': 'DEVICE_SCREENSHOT',
  'qr-code-generator': 'QR_CODE',
};

export const toolsListName: {[key: string]: string} = {
  TECH_STACK: 'Discover Tech Stack',
  WHO_IS: 'Discover WHOIS',
  ACCESSIBILITY_CHECKER: 'Accessibility Audit',
  GRAMMAR_SPELLING: 'Grammar & Spelling Audit',
  TEXT_TO_SPEECH: 'Text to Speech',
  GET_WORD_COUNT: 'Content analysis',
  DEVICE_SCREENSHOT: 'Screenshot capture',
  QR_CODE: 'QR code generator',
};
export const toolsListTitle: {[key: string]: {title: string; subTitle: string}} = {
  TECH_STACK: {
    title: 'Identify the tech stack used on any website',
    subTitle: 'Find out what technologies are used by prospects or competitors powered by Hexomatic',
  },
  WHO_IS: {
    title: 'WHOIS Domain Lookup',
    subTitle:
      'Discover domain expiry, ownership, contact, registrar and nameserver information for any domain or IP powered by Hexomatic',
  },
  ACCESSIBILITY_CHECKER: {
    title: 'Check any web page for accessibility issues',
    subTitle:
      'Audit any page in seconds for accessibility problems using the Web Content Accessibility Guidelines (WCAG) 2.1, WCAG 2.0 and IBM Accessibility standards.',
  },
  GRAMMAR_SPELLING: {
    title: 'Check any web page for spelling and grammar mistakes',
    subTitle: 'Catch embarrassing typos, spelling, and grammar mistakes on any web page in seconds',
  },
  TEXT_TO_SPEECH: {
    title: 'Online text to speech generator with realistic AI voices',
    subTitle: 'Turn any text into the most natural-sounding speech powered by Hexomatic',
  },
  GET_WORD_COUNT: {
    title: 'Analyze any page for on-page content metrics',
    subTitle: 'Check keyword density, word count, reading time, and letter counts for any web page in seconds.',
  },
  DEVICE_SCREENSHOT: {
    title: 'Capture website screenshots for any device screen size online',
    subTitle:
      'Generate full-page website screenshots for a wide range of device screen sizes to preview how your pages look and test for responsive browser issues.',
  },
  QR_CODE: {
    title: 'QR code generator - Create QR codes for any URL, text or number',
    subTitle: 'Generate QR codes in seconds that can be used on stationary, posters, merchandise or product packaging.',
  },
};

export const automationsListForApiDocumentation = [
  'discover-tech-stack',
  'discover-whois',
  'create-screenshot',
  'discover-profile',
];
export const deviceOptions = [
  {
    label: 'Mobile Small',
    value: 'MOBILE_SMALL',
  },
  {
    label: 'Mobile Medium',
    value: 'MOBILE_MEDIUM',
  },
  {
    label: 'Mobile Large',
    value: 'MOBILE_LARGE',
  },
  {
    label: 'Tablet',
    value: 'TABLET',
  },
  {
    label: 'Laptop Small',
    value: 'LAPTOP_SMALL',
  },
  {
    label: 'Laptop Medium',
    value: 'LAPTOP_MEDIUM',
  },
  {
    label: 'Laptop Large',
    value: 'LAPTOP_LARGE',
  },
  {
    label: 'Desktop 4k',
    value: 'DESKTOP_4K',
  },
];

export const screenShotDelayOptions = [
  {label: 5, value: 5},
  {label: 10, value: 10},
  {label: 15, value: 15},
  {label: 30, value: 30},
];

export const automationOutputs = {
  2: ['transformed number'],
  8: ['transformed measurement unit'],
  18: ['reflected on input'],
  21: ['exported to Google Sheets'],
  33: ['google translation'],
  34: ['deepl translation'],
  44: ['transformed text'],
  47: ['inputted data'],
  92: ['reflected on input'],
  125: ['bigquery full csv', 'specified columns'],
  136: ['wordpress uploader'],
};

export const automationWithoutOutput = [1, 3, 5, 25, 26, 27, 29, 110, 114, 117];

export const ERROR_MESSAGES = {
  CREDIT_LIMIT_REACHED: 'Credit limit reached',
  PREMIUM_CREDIT_LIMIT_REACHED: 'Premium credit limit reached',
  STORAGE_IS_FULL: 'Storage is full',
} as any;
